import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./style.css";
import { userManager } from './oidcConfig';
import Front from "./Front.jsx";

export default function App() {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const login = () => {
        if (!sessionStorage.getItem("loginInProgress")) {
            console.log("Redirecting to login...");
            sessionStorage.setItem("loginInProgress", "true");
            userManager.signinRedirect();
        }
    };

    const logout = () => {
        console.log("Redirecting to logout...");
        userManager.signoutRedirect();
    };

    useEffect(() => {
        const handleRedirectCallback = async () => {
            if (window.location.href.includes('code=')) {
                try {
                    const user = await userManager.signinRedirectCallback();
                    console.log("Login successful");
                    setAuthenticated(true);
                    setUser(user);
                    sessionStorage.removeItem("loginInProgress");
                    navigate("/"); // Redirect after successful login
                } catch (error) {
                    console.error("Error during token exchange:", error);
                    sessionStorage.removeItem("loginInProgress");
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false); // Stop loading if not a login redirect
            }
        };

        handleRedirectCallback();
    }, [navigate]);

    const checkUser = async () => {
        try {
            const currentUser = await userManager.getUser();

            const adressesIp = [
                "213.41.124.105/29",
                "84.14.33.209/29",
                "62.129.4.192/29",
                "62.23.57.65/27",
                "62.23.57.67",
                "176.151.30.31"
            ]
            try {
                let response = await fetch("https://api64.ipify.org?format=json");
                let data = await response.json();
                const ip = data.ip;
                console.log("IP :", ip)
                if(adressesIp.includes(ip)) {
                    setLoading(false);
                    return;
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'IP :", error);
            }

            if (currentUser && !currentUser.expired) {
                console.log("User is authenticated");
                setAuthenticated(true);
                setUser(currentUser);
            } else if (!window.location.href.includes('code=') && !sessionStorage.getItem("loginInProgress")) {
                console.log("User not authenticated, initiating login...");
                login();
            }
        } catch (error) {
            console.error("Error checking user:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkUser();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    return <Front />;
}
